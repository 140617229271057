import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form as AntForm,
  DatePicker,
  Input,
  Checkbox,
  message,
} from "antd";
import {
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { MainTable } from "components";
import { instance } from "utils/axios";
import moment from "moment";

function ShiftHoliday() {

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [monthFilter, setMonthFilter] = useState(moment()); // Month filter state
  const [form] = AntForm.useForm();
  const [modal, contextHolder] = Modal.useModal();
  useEffect(() => {
    fetchData();
  }, [ monthFilter]);

  // Fetch data with optional month filter
  const fetchData = () => {
    setLoading(true);
   let date =monthFilter.format("YYYY-MM")
    instance({
      method: "get",
      url: `/shift/holidays?month=${date}`,
    })
      .then((res) => {
        setData(res.data);
      
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  // Submit new or edited data
  const handleSubmit = (values) => {
    setLoading(true);

    const formattedValues = {
      ...values,
      date: moment(values.date).format("YYYY-MM-DD"),
      id: editData?.id,
    };

    if (editData) {
      instance({
        method: "put",
        url: `/shift/holiday`,
        data: formattedValues,
      })
        .then(() => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/shift/holiday",
        data: formattedValues,
      })
        .then(() => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  };

  // Handle edit button click
  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };

  // Handle delete button click
  const handleDelete = (id) => {
    modal.confirm({
      title: "Устгахдаа итгэлтэй байна уу?",
      content: "Энэ амралтын өдрийг устгах гэж байна.",
      okText: "Тийм",
      cancelText: "Үгүй",
      onOk: () => {
        instance({
          method: "delete",
          url: `/shift/holiday/${id}`,
        })
          .then(() => {
   
            fetchData();
          })
          .catch((err) => {
            console.error(err);
          
          });
      },
    });
  };

  // Handle add button click
  const handleAdd = () => {
    setEditData(null);
    setOpen(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  // Table columns
  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 50,
      align: "center",
      render: (text, row, index) => <span>{currentPage + index + 1}</span>,
    },
    {
      title: "Амралтын өдрийн нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Огноо",
      dataIndex: "date",
      key: "date",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Үйлдэл",
      dataIndex: "action",
      key: "action",
      width: 120,
      render: (text, row) => (
        <div className="flex gap-2">
          <Button size="small" onClick={() => handleEdit(row)}>
            <EditOutlined /> Засах
          </Button>
          <Button size="small" danger onClick={() => handleDelete(row.id)}>
            <DeleteOutlined /> Устгах
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col px-4 pb-4">
      {/* Filter and Add Button */}
      <div className="flex items-center mb-4 gap-4">
        <DatePicker
          picker="month"
          onChange={setMonthFilter}
          value={monthFilter}
          placeholder="Сараар шүүх"
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={handleAdd}
        >
          Нэмэх
        </Button>
      </div>

      {/* Table */}
      <MainTable
        dataTable={data}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        title="Бүх нийтийн амралтын өдрүүд"
      />

      {/* Modal */}
      <Modal
        title={editData ? "Амралтын өдөр засах" : "Амралтын өдөр нэмэх"}
        open={open}
        destroyOnClose
        width={600}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <AntForm
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            name: editData?.name || "",
            date: editData ? moment(editData.date) : null,
            description: editData?.description || "",
          }}
        >
          <AntForm.Item
            label="Амралтын өдрийн нэр"
            name="name"
            rules={[
              { required: true, message: "Амралтын өдрийн нэр оруулна уу!" },
            ]}
          >
            <Input />
          </AntForm.Item>

          <AntForm.Item
            label="Огноо"
            name="date"
            rules={[{ required: true, message: "Огноо сонгоно уу!" }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </AntForm.Item>

          <AntForm.Item label="Тайлбар" name="description">
            <Input.TextArea rows={3} />
          </AntForm.Item>
        </AntForm>
      </Modal>
      {contextHolder}
    </div>
  );
}

export default ShiftHoliday;
