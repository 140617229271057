import { Input, TimePicker, InputNumber } from "antd";

export default (editData = {}) => [
  {
    label: "Нэр",
    name: "name",
    rules: [{ required: true, message: "Төрлийн нэрийг оруулна уу!" }],
    className: "col-span-12 md:col-span-12 mb-0",
    inputProps: {
      placeholder: "Төрлийн нэр",
      className: "w-full",
    },
  },
  {
    label: "Эхлэх цаг",
    name: "startTime",
    rules: [{ required: true, message: "Эхлэх цагийг оруулна уу!" }],
    className: "col-span-12 md:col-span-6 mb-0",
    inputProps: {
      format: "HH:mm",
      className: "w-full",
      use12Hours: false,
    },
    type: "time",
  },
  {
    label: "Амралтын өдөр эхлэх цаг",
    name: "weekendStartTime",
   
    className: "col-span-12 md:col-span-6 mb-0",
    inputProps: {
      format: "HH:mm",
      className: "w-full",
      use12Hours: false,
    },
    type: "time",
  },
  {
    label: "Хугацаа",
    name: "duration",
    rules: [{ required: true, message: "Хугацаа оруулна уу!" }],
    className: "col-span-12 md:col-span-6 mb-0",
    inputProps: {
      className: "w-full",
    },
    type: "number",
  },
  {
    label: "Хонох ээлж",
    name: "nightShift",
    className: "col-span-12 md:col-span-6 mb-0",
    inputProps: {
      className: "w-full",
    },
    type: "checkbox",
  },
  // {
  //   label: "Өнгө сонгох",
  //   name: "color",
  //   className: "col-span-12 md:col-span-6 mb-0",
  //   inputProps: {
  //     type: "color",
  //     className: "w-full",
  //     defaultValue: editData?.color || "#ffffff",
  //   },
  // },
  {
    label: "Албан тушаал",
    name: "role",
    className: "col-span-12 md:col-span-12 mb-0",
    type: "select",
    inputProps: {
      placeholder: "Албан тушаал",

      options: [
        { value: "Ресейпшн", label: "Ресейпшн" },
        { value: "Техникч", label: "Техникч" },
        { value: "Менежер", label: "Менежер" },
        { value: "Эмч", label: "Эмч" },
        { value: "Эрхлэгч", label: "Эрхлэгч" },
      ],
    },
  },
  {
    label: "Төхөөрөмж",
    name: "servicetypeid",
    rules: [{ required: true, message: "Төхөөрөмж" }],
    className: "col-span-12 md:col-span-6 mb-0",
    inputProps: {
      className: "w-full",
      options: [
        { value: 1, label: "MRI" },
        { value: 2, label: "CT" },
      ],
    },
    type: "select",
  },
];
