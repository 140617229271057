import React, { useEffect, useState } from "react";

import { Button, List, Segmented, Table } from "antd";
import { MainTable } from "components";
function DoctorHour({ data, className = "", title, servicetypeid }) {
  const [viewMode, setViewMode] = useState("table");
  const [totalCount, setTotal] = useState(
    0
    // data.reduce((a, b) => a.cnt + b.cnt, 0)
  );
  // const [data, setData] = useState(dataService); ///data.reduce((a, b) => a.cnt + b.cnt, 0);

  const columns = [
    {
      title: "Нэр",
      dataIndex: "firstname",
      key: "firstname",
      width:200
    },
    {
      title: "Нийт Тоо",
      dataIndex: "cnt",
      key: "cnt",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
    },
    {
      title: "Энгийн хариу дундаж",
      dataIndex: "avgHour",
      key: "avgHour",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
      // ),
    },
    {
      title: "48+ цагт гарсан хариуны тоо",
      dataIndex: "cnt48",
      key: "cnt48",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
      // ),
    },
    {
      title: "Яаралтай хариуны тоо",
      dataIndex: "cntUrgent",
      key: "cntUrgent",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
      // ),
    },
    {
      title: "Яаралтай хариуны дундаж хугацаа",
      dataIndex: "urgentAvg",
      key: "urgentAvg",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
      // ),
    },
    {
      title: "Яаралтай 36+ цагт гарсан хариуны тоо",
      dataIndex: "cntUrgent36",
      key: "cntUrgent36",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
      // ),
    },
  ];
  return (
    <div
      className={` bg-white rounded-lg shadow p-3 flex flex-col ${className}`}
    >
      <div className="flex gap-5">
        <div className="border rounded-lg overflow-hidden flex-1">
          {/* <div className="py-2 px-2 border-b">Дундаж онш бичилт</div> */}
          <MainTable
            dataTable={
              data 
            }
            columns={columns}
            title={title}
          />
        </div>
      </div>
    </div>
  );
}

export default DoctorHour;
