import React, { useEffect, useState } from "react";
import { Table, Select, DatePicker, Button, Tag } from "antd";
import moment from "moment";
import { instance } from "utils/axios";
import * as XLSX from "xlsx";

const { Option } = Select;

const ShiftSchedule = () => {
  const [configs, setConfigs] = useState([]);
  const [selectedRole, setSelectedRole] = useState("Техникч");
  const [selectedServicetype, setSelectedServicetype] = useState(null); // New state for servicetype filter
  const [monthYear, setMonthYear] = useState(moment());
  const [data, setData] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchConfigs();
  }, []);

  useEffect(() => {
    fetchData();
    fetchHolidays();
  }, [monthYear, selectedRole, selectedServicetype]);

  const fetchConfigs = () => {
    setLoading(true);
    instance
      .get("/shift/configs")
      .then((res) => {
        setConfigs(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const fetchHolidays = () => {
    const formattedMonthYear = monthYear.format("YYYY-MM");
    instance({
      method: "get",
      url: `/shift/holidays?month=${formattedMonthYear}`,
    })
      .then((res) => {
        setHolidays(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const fetchData = () => {
    setLoading(true);
    const formattedMonthYear = monthYear.format("YYYY-MM");
    instance
      .get(`/shift/schedules?date=${formattedMonthYear}&role=${selectedRole}`)
      .then((res) => {
        const filteredData = res.data.filter((item) =>
          selectedServicetype
            ? item.servicetypeid === selectedServicetype
            : true
        ); // Filter by servicetype if selected
        setData(formatData(filteredData, monthYear.daysInMonth()));
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const formatData = (responseData, daysInMonth) => {
    return responseData.map((item) => {
      const formattedItem = {
        key: item.userId,
        name: item.fullName,
        role: item.role,
        fontHour: item.fontHour,
        workHour: item.workHour,
        nigthShiftCount: item.nigthShiftCount,
        overHour:
          item.workHour > item.fontHour ? item.workHour - item.fontHour : 0,
      };
      for (let day = 1; day <= daysInMonth; day++) {
        formattedItem[`day-${day}`] = item[day] || null;
      }
      return formattedItem;
    });
  };

  const handleRoleFilterChange = (value) => {
    setSelectedRole(value);
  };

  const handleServicetypeFilterChange = (value) => {
    setSelectedServicetype(value);
  };

  const handleMonthChange = (value) => {
    setMonthYear(value || moment());
  };

  const handleCellChange = (recordKey, dayKey, value) => {
    const day = parseInt(dayKey.split("-")[1]);
    const date = monthYear.clone().date(day).format("YYYY-MM-DD");
    const userId = recordKey;

    instance
      .post("/shift/schedule", { date, configId: value ?? null, userId })
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        console.error(err);
      });
  };
const exportToExcel = () => {
  // Define the Heading for columns
  const Heading = [
    { key: "name", label: "Нэр" },
    { key: "fontHour", label: "Фонт цаг" },
    { key: "workHour", label: "Ажилласан цаг" },
    { key: "nigthShiftCount", label: "Хоносон тоо" },
    { key: "overHour", label: "Илүү цаг" },
    ...Array.from({ length: daysInMonth }, (_, index) => ({
      key: `day-${index + 1}`,
      label: `Өдөр-${index + 1}`,
    })), // Day columns dynamically added
  ];

  // Map data to match the Heading structure
  const formattedData = data.map((item) => {
    const row = {};

    Heading.forEach(({ key, label }) => {
      if (key.startsWith("day-")) {
        // Handle day-specific keys
        const dayIndex = key.split("-")[1]; // Extract day number
        const configId = item[`day-${dayIndex}`];
        const config = configs.find((c) => c.id === configId);

        row[label] = config
          ? `${config.duration}`
          : ""; // Add config name and servicetype or leave empty
      } else {
        row[label] = item[key] || ""; // Add other keys
      }
    });

    return row;
  });

  // Create worksheet with formatted data
  const worksheet = XLSX.utils.json_to_sheet(formattedData);

  // Set custom column widths
  // const columnWidths = Heading.map(({ label }) => ({
  //   wch: label.length > 20 ? label.length + 5 : 20, // Set dynamic column width
  // }));
  // worksheet["!cols"] = columnWidths;

  // Create the workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Schedule");

  // Trigger file download
  XLSX.writeFile(workbook, `Schedule_${monthYear.format("YYYY_MM")}.xlsx`);
};






  const daysInMonth = monthYear.daysInMonth();
  const columns = [
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 150,
    },
    {
      title: "Фонт",
      dataIndex: "fontHour",
      key: "fontHour",
      fixed: "left",
    },
    {
      title: "Ажиллах",
      dataIndex: "workHour",
      key: "workHour",
      fixed: "left",
    },
    {
      title: "Илүү",
      dataIndex: "overHour",
      key: "overHour",
      fixed: "left",
    },
    {
      title: "Хонох",
      dataIndex: "nigthShiftCount",
      key: "nigthShiftCount",
      fixed: "left",
    },
    ...Array.from({ length: daysInMonth }, (_, index) => {
      const day = index + 1;
      const currentDate = monthYear.clone().date(day).format("YYYY-MM-DD");
      const isWeekend =
        monthYear.clone().date(day).day() === 0 ||
        monthYear.clone().date(day).day() === 6;
      const isHoliday = holidays.some(
        (holiday) => holiday.date === currentDate
      );

      const backgroundClass = isHoliday
        ? "bg-pink-200 hover:bg-pink-300"
        : isWeekend
        ? "bg-orange-200 hover:bg-orange-300"
        : "bg-white hover:bg-gray-100";

      return {
        title: day,
        dataIndex: `day-${day}`,
        key: `day-${day}`,
        render: (text, record) => ({
          props: {
            className: backgroundClass,
          },
          children: (
            <div>
              <Select
                bordered={false}
                className={`w-full ${backgroundClass}`}
                value={text}
                onChange={(value) =>
                  handleCellChange(record.key, `day-${day}`, value)
                }
                allowClear
              >
                {configs.map((config) => (
                  <Option key={config.id} value={config.id}>
                    {config.name}{" "}
                    <Tag color={config.servicetypeid === 1 ? "red" : "green"}>
                      {config.servicetype.name}
                    </Tag>
                  </Option>
                ))}
              </Select>
            </div>
          ),
        }),
      };
    }),
  ];

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-4">Shift Schedule</h2>

      <div className="flex items-center gap-4 mb-4">
        <Select
          placeholder="Select Role"
          onChange={handleRoleFilterChange}
          value={selectedRole}
          className="w-52"
        >
          <Option value="Ресейпшн">Ресейпшн</Option>
          <Option value="Техникч">Техникч</Option>
          <Option value="Менежер">Менежер</Option>
          <Option value="Эмч">Эмч</Option>
          <Option value="Эрхлэгч">Эрхлэгч</Option>
        </Select>

        <Select
          placeholder="Select Servicetype"
          onChange={handleServicetypeFilterChange}
          value={selectedServicetype}
          className="w-52"
        >
          {configs.map((config) => (
            <Option key={config.servicetypeid} value={config.servicetypeid}>
              {config.servicetype.name}
            </Option>
          ))}
        </Select>

        <DatePicker
          picker="month"
          value={monthYear}
          onChange={handleMonthChange}
          format="YYYY-MM"
          className="w-52"
        />

        <Button type="primary" onClick={exportToExcel}>
          Export to Excel
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        pagination={false}
        scroll={{ x: "max-content" }}
        className="shadow-md"
      />
    </div>
  );
};

export default ShiftSchedule;
