import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form as AntForm,
  Input,
  Select,
  InputNumber,
  message,
} from "antd";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import fields from "./fields";

function ShiftCheck() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [filter, setFilter] = useState(1);
  const [form] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  // Өгөгдөл татах функц
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await instance.get("/shift/checks");
       setData(res.data);
       setFilteredData(res.data.filter((item) => item.servicetypeid == filter));
    } catch (error) {
      console.error(error);
      message.error("Өгөгдөл татахад алдаа гарлаа!");
    } finally {
      setLoading(false);
    }
  };

  // Шинэ болон засах мэдээлэл хадгалах функц
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (editData) {
        await instance.put(`/shift/check`, values);
  
      } else {
        await instance.post("/shift/check", values);

      }
      fetchData();
      handleCloseModal();
    } catch (error) {
      console.error(error);
      message.error("Алдаа гарлаа!");
    } finally {
      setLoading(false);
    }
  };

  // Засах үйлдэл
  const handleEdit = (row) => {
    setEditData(row);
    form.setFieldsValue(row);
    setOpen(true);
  };

  // Модал хаах
  const handleCloseModal = () => {
    setOpen(false);
    setEditData(null);
    form.resetFields();
  };

  // Шүүлтүүр өөрчлөх функц
  const handleFilterChange = (value) => {
    setFilter(value);
    if (value === null) {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter((item) => item.servicetypeid === value));
    }
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Төхөөрөмж",
      dataIndex: "servicetypeid",
      key: "servicetypeid",
      render: (text, row) => <span>{row.servicetype?.name}</span>,
    },
    {
      title: "Утгын төрөл",
      dataIndex: "valueType",
      key: "valueType",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <EditOutlined /> Засах
        </Button>
      ),
    },
  ];

  return (
    <div className="flex flex-col px-4 pb-4">
      {/* Шүүлтүүр хэсэг */}
      <div className="flex items-center mb-4">
        <Select
          placeholder="Төхөөрөмж сонгох"
          onChange={handleFilterChange}
          // allowClear
          value={filter}
          style={{ width: 200 }}
        >
          <Select.Option value={1}>MRI</Select.Option>
          <Select.Option value={2}>CT</Select.Option>
        </Select>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          className="ml-4"
          onClick={() => setOpen(true)}
        >
          Нэмэх
        </Button>
      </div>

      <MainTable
        dataTable={filteredData}
        columns={columns}
        loading={loading}
        pagination={true}
        title="Ээлж хүлээлцэх тохиргоо"
      />

      {/* Модал */}
      <Modal
        title={
          editData
            ? "Ээлж хүлээлцэх тохиргоо засах"
            : "Ээлж хүлээлцэх тохиргоо нэмэх"
        }
        open={open}
        onCancel={handleCloseModal}
        onOk={form.submit}
        confirmLoading={loading}
        destroyOnClose
        width={600}
        okText="Хадгалах"
      >
        <Form
          form={form}
          fields={fields(editData)}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
        />
      </Modal>
    </div>
  );
}

export default ShiftCheck;
