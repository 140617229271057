import React, { useEffect, useState } from "react";
import { Button, Modal, Tag, Form as AntForm, Select } from "antd";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import fields from "./fields";
import { Link } from "react-router-dom";
import moment from "moment";

const { Option } = Select;

function ShiftConfig() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleFilter, setRoleFilter] = useState(null);
  const [serviceTypeFilter, setServiceTypeFilter] = useState(null);
  const [form] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, [currentPage]);


  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/shift/configs`,
    })
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  // Шинэ болон засах мэдээлэл хадгалах функц
  const handleSubmit = (values) => {
    setLoading(true);

    // TimePicker-ээс авсан `startTime` болон `endTime` утгыг хөрвүүлэх
    const formattedValues = {
      ...values,
      startTime: moment(values.startTime).format("HH:mm:ss"),
      weekendStartTime: values.weekendStartTime
        ? moment(values.weekendStartTime).format("HH:mm:ss")
        : null,
      endTime: moment(values.endTime).format("HH:mm:ss"),
      id: editData?.id,
    };

    if (editData) {
      instance({
        method: "put",
        url: `/shift/config`,
        data: formattedValues,
      })
        .then(() => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/shift/config",
        data: formattedValues,
      })
        .then(() => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  };

  // Шүүлтүүр өөрчлөх функц
  const handleRoleFilterChange = (value) => {
    setRoleFilter(value);
    applyFilters(value, serviceTypeFilter);
  };

  const handleServiceTypeFilterChange = (value) => {
    setServiceTypeFilter(value);
    applyFilters(roleFilter, value);
  };

  const applyFilters = (role, serviceType) => {
    let filtered = data;
    if (role) {
      filtered = filtered.filter((item) => item.role === role);
    }
    if (serviceType) {
      filtered = filtered.filter((item) => item.servicetypeid === serviceType);
    }
    setFilteredData(filtered);
  };

  // Засах товчлуур дарах үед
  const handleEdit = (row) => {
    setEditData(row);
    setIsEdit(true);
    setOpen(true);
  };

  // Шинэ бичлэг нэмэх товчлуур дарах үед
  const handleAdd = () => {
    setIsEdit(false);
    setEditData(null);
    setOpen(true);
  };

  // Модал хаах функц
  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  // Багана тодорхойлох
  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 50,
      align: "center",
      render: (text, row, index) => <span>{currentPage + index + 1}</span>,
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Төхөөрөмж",
      dataIndex: "servicetypeid",
      key: "servicetypeid",
      render: (text, row) => <span>{row.servicetype?.name}</span>,
    },
    {
      title: "Эхлэх цаг",
      dataIndex: "startTime",
      key: "startTime",
    },
    {
      title: "Амралтын өдрийн цаг",
      dataIndex: "weekendStartTime",
      key: "weekendStartTime",
    },
    {
      title: "Дуусах цаг",
      dataIndex: "endTime",
      key: "endTime",
    },
    {
      title: "Хугацаа",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Албан тушаал",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Хонох ээлж",
      dataIndex: "nightShift",
      key: "nightShift",
      render: (text, row) => (
        <span>{text ? <Tag color="red">Хонох</Tag> : "Өдөр"}</span>
      ),
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <EditOutlined /> Засах
        </Button>
      ),
    },
  ];

  return (
    <div className="flex flex-col px-4 pb-4">
      {/* Шүүлтүүр хэсэг */}
      <div className="flex items-center mb-4 gap-4">
        <Select
          placeholder="Албан тушаал сонгох"
          onChange={handleRoleFilterChange}
          allowClear
          style={{ width: 200 }}
        >
          <Option value="Ресейпшн">Ресейпшн</Option>
          <Option value="Техникч">Техникч</Option>
          <Option value="Менежер">Менежер</Option>
          <Option value="Эмч">Эмч</Option>
          <Option value="Эрхлэгч">Эрхлэгч</Option>
        </Select>

        <Select
          placeholder="Төхөөрөмж сонгох"
          onChange={handleServiceTypeFilterChange}
          allowClear
          style={{ width: 200 }}
        >
          <Option value={1}>MRI</Option>
          <Option value={2}>CT</Option>
        </Select>

        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={handleAdd}
        >
          Нэмэх
        </Button>
      </div>

      <MainTable
        dataTable={filteredData}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        title="Ээлжийн Тохиргоо"
      />

      <Modal
        title={editData ? "Ээлжийн төрөл засах" : "Ээлжийн төрөл нэмэх"}
        open={open}
        destroyOnClose
        width={600}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields(editData)}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
        />
      </Modal>
    </div>
  );
}

export default ShiftConfig;
