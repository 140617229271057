import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Button, Tag, Tooltip, Select, Space, Input, Checkbox } from "antd";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Modal, Form as AntForm, DatePicker, message } from "antd";
import { Form, MainTable, CompanySelect } from "components";
import { instance } from "utils/axios";
import { AuthContext } from "contexts";


const { Option } = Select;
export default function ShiftCalendar() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const role = authContext.state.userInfo.profile.role;
  const userId = authContext.state.userInfo.profile.id; // User ID from context
  const [data, setData] = useState([]);
  const [config, setConfig] = useState([]);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [configTypeOptions, setConfigTypeOptions] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  const [form] = AntForm.useForm();
  const [loading, setLoading] = useState(false);
  const [technicians, setTechnicians] = useState([]);
  const [selectedService, setService] = useState(-1);
  const [selectedRole, setRole] = useState(role);
  const [onlyMe, setOnlyMe] = useState(true); // State for the "Only Me" checkbox

  useEffect(() => {
    action.handleSidebarKey("shift_calendar");
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedService, selectedRole, onlyMe]); // Include "onlyMe" in dependencies

  const fetchData = () => {
    instance({
      method: "get",
      url: `/shift/calendar?servicetypeid=${selectedService}&role=${selectedRole}&onlyMe=${
        onlyMe?1:0
      }`,
    })
      .then((res) => {
        setData(
          res.data.map((item) => ({
            data: item,
            id: item.id,
            title: `${item.systemuser?.lastname.charAt(0)}. ${
              item.systemuser?.firstname
            }`,
            start: item?.date,
            end: item?.date,
            color: "transparent",
          }))
        );
      })
      .catch((err) => {
        console.error(err);
        message.error("Алдаа гарлаа");
      });
  };

  const handleEventClick = (clickInfo) => {
    const data = clickInfo.event.extendedProps.data;
    setEditData(data);
    setOpen(true);
  };

  const renderEventContent = (eventInfo) => {
    const data = eventInfo.event.extendedProps.data;
    const tagColor = data.servicetypeid == 1 ? "red" : "green";
    return (
      <>
        <Tag
          className="w-full whitespace-normal break-words"
          color={tagColor}
          onClick={() => handleEventClick(eventInfo)}
        >
          <b>{data?.fullName}</b>
          <br />
          <Tag color={tagColor}>{data.serviceName}</Tag>
          <span
            style={{
              width: "100%",
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            <Tag color="orange">{data?.configName}</Tag>
            <Tag color={tagColor}>{data?.startTime}</Tag>
            <br />
          </span>
        </Tag>
      </>
    );
  };

  return (
    <div className="flex flex-col px-4 pb-4">
      <h1>Хуваарь</h1>

      <div className="flex gap-4">
        <div>
          Төхөөрөмж:
          <Select
            placeholder="Төхөөрөмж сонгох"
            onChange={(e) => setService(e)}
            value={selectedService}
            style={{ width: 200 }}
          >
            <Option value={-1}>Бүгд</Option>
            <Option value={1}>MRI</Option>
            <Option value={2}>CT</Option>
          </Select>
          <Select
            placeholder="Албан тушаал сонгох"
            value={selectedRole}
            onChange={(e) => setRole(e)}
            style={{ width: 200 }}
          >
            <Option value="Бүгд">Бүгд</Option>
            <Option value="Ресейпшн">Ресейпшн</Option>
            <Option value="Техникч">Техникч</Option>
            <Option value="Менежер">Менежер</Option>
            <Option value="Эмч">Эмч</Option>
            <Option value="Эрхлэгч">Эрхлэгч</Option>
          </Select>
        </div>
        <div>
          <Checkbox
            checked={onlyMe}
            onChange={(e) => setOnlyMe(e.target.checked)}
          >
            Зөвхөн миний хуваарь
          </Checkbox>
        </div>
      </div>
      <FullCalendar
        aspectRatio={2}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        firstDay={1}
        weekends={true}
        events={data}
        eventContent={(e) => renderEventContent(e)}
      />
      {contextHolder}
    </div>
  );
}

