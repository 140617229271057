import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Button, Tag, Tooltip, Select, Space, Input, Checkbox } from "antd";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Modal, Form as AntForm, DatePicker, message } from "antd";
import { Form, MainTable, CompanySelect } from "components";
import { BiTrash } from "react-icons/bi";
import moment from "moment";
import { instance } from "utils/axios";
// import fields from "./fields";
import { AuthContext } from "contexts";

import fields from "./fields";
export default function SurgeryPlan() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const role = authContext.state.userInfo.profile.role;
  const userId = authContext.state.userInfo.profile.id;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [types, setTypes] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const [form] = AntForm.useForm();
  useEffect(() => {
    action.handleSidebarKey("surgery");
    instance({
      method: "get",
      url: `/surgery/types`,
    })
      .then((res) => {
        setTypes(
          res.data.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
            color: item.color,
          }))
        );
      })
      .catch((err) => {});
    instance({
      method: "get",
      url: `/systemusers?role=Хэвтэн&status=Идэвхитэй&hospital=2`,
    })
      .then((res) => {
        setDoctors(
          res.data.map((item) => ({
            value: item.id,
            label: `${item.lastname.charAt(0)}. ${item.firstname}`,
            text: `${item.lastname.charAt(0)}. ${item.firstname}`,
          }))
        );
      })
      .catch((err) => {})
      .then(() => setLoading(false));
    fetchData();
  }, []);
  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/surgeries`,
    })
      .then((res) => {
        setData(
          res.data.map((item) => ({
            data: item,
            id: item.id,
            title: `${item.name ?? ""},${item.age ?? ""}/${item.gender ?? ""} `,
            eventColor: item.color,
            start: item.surgeryDate,
            end: item.surgeryDate,
            color: "transparent",
          }))
        );
      })
      .catch((err) => {})
      .then(() => setLoading(false));

    setLoading(true);
  };
  const handleDateClick = (arg) => {
    setIsEdit(true);
    setEditData({
      id: null,
      surgeryDate: arg.date,
    });
    setOpen(true);
  };
  const handleEventClick = (clickInfo) => {
    let data = clickInfo.event.extendedProps.data;
    const doctorIds = data.doctors.map((x) => x.id);
    data.doctorIds = doctorIds;
    setEditData(data);
    setOpen(true);

  };
  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };
  const handleSubmit = (values) => {
    setLoading(true);
    if (editData.id) {
      instance({
        method: "put",
        url: "/surgery",
        data: {
          ...values,
          id: editData.id,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/surgery",
        data: {
          ...values,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };
  const handleDelete = () => {
    setLoading(true);
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Устгахдаа итгэлтэй байна уу",
      okText: "Тийм",
      cancelText: "Үгүй",
      onOk() {
        return new Promise((resolve, reject) => {
          //  r/:id
          instance({
            method: "delete",
            url: "/surgery/" + editData.id,
          })
            .then((res) => {
              resolve("ok");
              handleCloseModal();
              fetchData();
            })
            .catch((err) => {
              if (err.response.status == 403) {
                modal.warning({
                  title: "Анхаарна уу !",
                  content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                });
              }
              reject();
            });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        handleCloseModal();
      },
    });
  };
  const renderEventContent = (eventInfo) => {
    const data = eventInfo.event.extendedProps.data;

    return (
      <>
        <Tag
          className="w-full whitespace-normal break-words"
          color={data.color}
          onClick={() => handleEventClick(eventInfo)}
        >
          <b>
            {eventInfo.event.title} |{data.typeName}{" "}
          </b>

          <br></br>
          <span
            style={{
              width: "100%",
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            <Tag color={data.color}>
              {data.startTime
                ? moment(data.startTime, "HH:mm").format("HH:mm")
                : ""}
              -
              {data.endTime
                ? moment(data.endTime, "HH:mm").format("HH:mm")
                : ""}
            </Tag>
            <Tag
              style={{
                // width: "100%",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
              color={data.color}
            >
              DS:{data.diagnose}
            </Tag>{" "}
            <Tag
              style={{
                width: "100%",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
              color={data.color}
            >
              Мэс заслын нэр:{data.operation}
            </Tag>
            <Tag color={data.color}>{data.phone}</Tag>
            {data.doctors.map((doctor, index) => (
              <span key={index}>
                {doctor.lastName.charAt(0)}.{doctor.firstName},
              </span>
            ))}
            <br></br>
            {data.description}
          </span>
        </Tag>
      </>
    );
  };

  return (
    <div className="flex flex-col px-4 pb-4">
      <h1>Хуваарь</h1>
      <div>
        {types.map((x) => {
          return (
            <Tag
              className="w-[150px]"
              key={x.value}
              value={x.value}
              color={x.color}
            >
              {x.label}
            </Tag>
          );
        })}
      </div>
      <FullCalendar
        aspectRatio={2}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        firstDay={1}
        weekends={false}
        dateClick={(e) => handleDateClick(e)}
        events={data}
        eventContent={(e) => renderEventContent(e)}
      />
      <Modal
        title={
          editData ? "Мэс заслын мэдээлэл засах" : "Мэс заслын мэдээлэл оруулах"
        }
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
        footer={[
          <Button key="cancel" onClick={loading ? null : handleCloseModal}>
            Цуцлах
          </Button>,
          userId == 171 || userId==176 || editData?.userId == userId ? (
            <Button
              key="delete"
              type="danger"
              onClick={loading ? null : handleDelete}
              hidden={editData?.id == null}
            >
              Устгах
            </Button>
          ) : (
            <></>
          ),
          <Button key="save" type="primary" onClick={form.submit}>
            Хадгалах
          </Button>,
        ]}
      >
        <Form
          form={form}
          fields={fields({ types, doctors, handleDelete })}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
      {contextHolder}
    </div>
  );
}
