import { Input, Select } from "antd";

export default (editData = {}) => [
  {
    label: "Нэр",
    name: "name",
    rules: [{ required: true, message: "Төрлийн нэрийг оруулна уу!" }],
    className: "col-span-12 md:col-span-12 mb-0",
    inputProps: {
      placeholder: "Төрлийн нэр",
      className: "w-full",
    },
  },
  {
    label: "Төхөөрөмж",
    name: "servicetypeid",
    rules: [{ required: true, message: "Төхөөрөмж сонгоно уу!" }],
    className: "col-span-12 md:col-span-6 mb-0",
    type: "select",
    inputProps: {
      className: "w-full",

      options: [
        { value: 1, label: "MRI" },
        { value: 2, label: "CT" },
      ],
    },
  },
  {
    label: "Оруулах төрөл",
    name: "valueType",
    rules: [{ required: true, message: "Утгын төрлийг сонгоно уу!" }],
    type: "select",
    className: "col-span-12 md:col-span-6 mb-0",
    inputProps: {
      className: "w-full",

      options: [
        { value: "number", label: "Тоо" },
        { value: "text", label: "Текст" },
        { value: "boolean", label: "Check" },
      ],
    },
  },
];
